import { render, staticRenderFns } from "./pre-heading.vue?vue&type=template&id=00bd8638&scoped=true"
import script from "./pre-heading.vue?vue&type=script&lang=js"
export * from "./pre-heading.vue?vue&type=script&lang=js"
import style0 from "./pre-heading.vue?vue&type=style&index=0&id=00bd8638&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00bd8638",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VContainer,VSheet})
