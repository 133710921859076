var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.isHomeActive)?_c('v-container',[_c('v-sheet',{staticClass:"pa-3",style:({
        backgroundImage: `url(${require('/assets/images/bandeau-bg.png')})`,
      }),attrs:{"color":"#198737","dark":""}},[_c('div',[_c('v-btn',{staticClass:"ma-2 custom-color",attrs:{"rounded":"","depressed":"","color":"#ffaa00"},on:{"click":function($event){return _vm.handleOpen(
              'https://www.bklaradio.fr/?utm_source=bkonair&utm_medium=event&utm_campaign=event&utm_id=bklaradio'
            )}}},[_c('img',{staticClass:"pr-1",attrs:{"src":require('~/assets/images/play.svg'),"height":"32","alt":"play"}}),_vm._v("\n          Ecouter BK LA RADIO\n        ")]),_vm._v("\n        Mot de passe : bklaradio\n      ")],1)])],1):_vm._e(),_vm._v(" "),(_vm.isUniverse('61adf77c4bc5fe344f08d4d2'))?_c('v-container',[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"#c4302b","dark":""}},[_c('div',[_vm._v("\n        Retrouvez également l'intégralité de nos campagnes de pub sur notre\n        chaîne YouTube\n        "),_c('v-btn',{staticClass:"ma-2 custom-color",attrs:{"rounded":"","depressed":"","color":"#ffaa00"},on:{"click":function($event){return _vm.handleOpen(
              'https://www.youtube.com/channel/UCOUYCp_k1mHGwXAz3uRpjyw/videos'
            )}}},[_c('img',{staticClass:"pr-1",attrs:{"src":require('~/assets/images/play-yt.svg'),"height":"24","alt":"Play Youtube"}}),_vm._v("\n          Découvrir\n        ")])],1)])],1):_vm._e(),_vm._v(" "),_c('w-block',{attrs:{"name":"cta"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }