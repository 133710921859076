var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700","max-width":"100%","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"mx-auto",class:{
      'w-bk-form-modal': true,
      'w-bk-form-modal--mobile': _vm.$voicer.isMobile,
    }},[_c('v-toolbar',{staticClass:"color: #fbde51",attrs:{"flat":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.open = false}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close-thick")])],1)],1),_vm._v(" "),_c('v-img',{attrs:{"src":_vm.$voicer.isMobile
          ? require('~/assets/images/popupmobile.png')
          : require('~/assets/images/popupdesktop.png'),"alt":"image popup","max-height":_vm.$voicer.isMobile ? 368 : undefined},on:{"click":function($event){return _vm.handleOpen(
          'http://www.areyounet.com/runet/client/Reponse?i=683062815&s=9FE6B210'
        )}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }