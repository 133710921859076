const $merge = require('lodash.merge')
const $get = require('lodash.get')
const $clone = require('lodash.clone')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}
const USER_SIEGE_COLLECTION = ['siege']
const USER_CIE_COLLECTION = ['siege', 'directeur_compagnie']
const USER_FRA_COLLECTION = ['siege', 'franchise']
const USER_NOISE_COLLECTION = ['siege', 'franchise', 'directeur_compagnie']

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '609accd6ab282e6e9858a830',
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    metadatas: {
      name: 'BK On Air',
      colorPrimary: '#d72300',
      colorAccent: '#FF8732',
      supportEmailAddress: 'support@bkonair.fr',
    },
    analytics: {
      matomo: '10',
    },
    nuxt: {
      head: {
        title: "BK On Air",
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Retrouvez vos podcasts sur BK On Air`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: "BK On Air",
          },
          {
            hid: 'ogTitle',
            name: 'og:title',
            content: 'BK On Air',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content: `Retrouvez vos podcasts sur BK On Air`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        view: {
          allowHeadingOnMobile: false,
        },
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '6177f1010298d78503f8e9a9',
            i18n: {
              fr: {
                title: 'Les actus du siège',
              },
            },
            heading: {
              color: '#FF8732',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              overlay: false,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/siege.png')
                  : require('~/assets/images/siege.png')
              },
            },
            isVisible({ context }) {
              const { state } = context.$store
              const userSegments = $get(
                state.auth.user,
                'protected.segments',
                []
              )

              const isForbiddenFromSegment = userSegments.some((segment) => {
                return ['franchise', 'directeur_compagnie'].includes(
                  segment.name
                )
              })

              if (isForbiddenFromSegment === true) {
                return false
              }

              return USER_SIEGE_COLLECTION.some((name) => {
                return userSegments.map((seg) => seg.name).includes(name)
              })
            },
          },
          {
            type: 'tag',
            tagId: '6177f141da8aae83c888d51a',
            i18n: {
              fr: {
                title: `Les actus de la Compagnie`,
              },
            },
            heading: {
              color: '#198737',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              overlay: false,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/compagnie.png')
                  : require('~/assets/images/compagnie.png')
              },
            },
            isVisible({ context }) {
              const { state } = context.$store
              const userSegments = $get(
                state.auth.user,
                'protected.segments',
                []
              )

              const isForbiddenFromSegment = userSegments.some((segment) => {
                return ['franchise'].includes(segment.name)
              })

              if (isForbiddenFromSegment === true) {
                return false
              }

              return USER_CIE_COLLECTION.some((name) => {
                return userSegments.map((seg) => seg.name).includes(name)
              })
            },
          },
          {
            type: 'tag',
            tagId: '6177f1560298d75843f8e9aa',
            i18n: {
              fr: {
                title: 'Les actus de la Franchise',
              },
            },
            heading: {
              color: '#502314',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              overlay: false,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/franchises.png')
                  : require('~/assets/images/franchises.png')
              },
            },
            isVisible({ context }) {
              const { state } = context.$store
              const userSegments = $get(
                state.auth.user,
                'protected.segments',
                []
              )

              const isForbiddenFromSegment = userSegments.some((segment) => {
                return ['directeur_compagnie'].includes(segment.name)
              })

              if (isForbiddenFromSegment === true) {
                return false
              }

              return USER_FRA_COLLECTION.some((name) => {
                return userSegments.map((seg) => seg.name).includes(name)
              })
            },
          },
          {
            type: 'tag',
            tagId: '61adf77c4bc5fe344f08d4d2',
            i18n: {
              fr: {
                title: `On fait du bruit`,
              },
            },
            heading: {
              color: '#FFAA00',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              overlay: false,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/onfaitdubruit.png')
                  : require('~/assets/images/onfaitdubruit.png')
              },
            },
            isVisible({ context }) {
              const { state } = context.$store
              const userSegments = $get(
                state.auth.user,
                'protected.segments',
                []
              )

              return USER_NOISE_COLLECTION.some((name) => {
                return userSegments.map((seg) => seg.name).includes(name)
              })
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
